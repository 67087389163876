<template>
  <v-select
    :items="timezones"
    @change="select"
    label="Timezone"
    item-text="timezone"
    item-value="id"
    v-model="timezone_id"
    :loading="loading"
    :rules="rule"
    offset-xs-10
  ></v-select>
</template>

<script>
export default {
  props: {
    id: Number,
    rules: { type: Array, default: null },
  },
  data: () => ({
    loading: false,
    timezones: [],
    timezone_id: null,
    rule : [
      (value) => !!value || 'Please select timezone'
    ]
  }),
  created() {
    this.loading = true;
    this.axios.get("/timezone?per_page=100").then(({ data }) => {
      this.timezones = data.timezones;
      this.loading = false;
      if (this.id) {
        this.timezone_id = this.id;
      }
    });
  },
  methods: {
    select() {
      this.$emit('input', this.timezone_id)
      this.$emit("select", this.timezone_id)
    }
  },
  watch: {
    id: function (val) {
      if(val)
      {
        this.timezone_id = val
      }
      
    }
  },
};
</script>