<template>
    <v-autocomplete 
    :items="sortFunc" 
    v-model="selected"
    @change="submit"
    :disabled="dis"
    label="Country" 
    :rules="rules" > </v-autocomplete>
</template>
<script>
export default {
    props : ['country', 'disabled'],
    data()
    {
        return {
            countries : [],
            selected : null,
            dis : false,
            rules : [(value) => !!value || "Please provide your country" ]
        }
    },
    watch : {
        country : function(val) {
            if(val)
            {
        
                this.selected = val
            }
        },
        disabled : function(val)
        {
            if(val)
            {
                this.dis = true
            }else{
                this.dis = false
            }
        }
    },
    computed: {
        sortFunc: function (){
            if(this.countries)
            {
                 return this.countries.slice().sort(function(a, b){
                    return (a > b) ? 1 : -1;
                });
            }else{
                return this.countries
            }
           
        }
    },
    created()
    {
        if(this.country)
        {
            this.selected = this.country
        }
        this.countries = this.$countries.names()
    },
    methods : {
        submit()
        {
             this.$emit('input', this.selected)
        }
    }
}
</script>